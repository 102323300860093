import PropTypes from 'prop-types';
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "routes";

// Import all middleware
import Authmiddleware from "routes/route";

// layouts Format
import VerticalLayout from "components/VerticalLayout/";
import HorizontalLayout from "components/HorizontalLayout/";
import NonAuthLayout from "components/NonAuthLayout";

import { Toaster } from "react-hot-toast"

import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Import scss
import "assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "helpers/firebase_helper";

import fakeBackend from "helpers/AuthType/fakeBackend"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.js">
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Worker>
      <Toaster
        toastOptions={{
          // style: { maxWidth: 500, },
          position: "top-right",
          className: "bg-primary fs-5 text-white",
          duration: 3000,
          icon: <i className="bx bx-info-circle" />,
          iconTheme: {
            primary: "none",
          },
          loading: {
            position: "top-center",
            icon: <i className="bx bx-sync bx-spin" />,
          },
          success: {
            position: "top-center",
            className: "bg-success fs-5 text-white",
            duration: 2000,
            icon: <i className="bx bx-check-circle" />,
            iconTheme: {
              primary: "none",
            },
          },
          error: {
            position: "top-right",
            className: "fs-5 text-white",
            duration: 4000,
            style: { backgroundColor: "red" },
            icon: <i className="bx bx-error" />,
            iconTheme: {
              primary: "none",
            },
          },
        }}
      />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStateToProps, null)(App);
