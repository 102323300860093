import React from 'react';

function MeetingView() {
  return (
    <div id="meetingView" className="container">
      {/* Remote Participant Container */}
      <div id="remoteParticipantContainer" className="d-flex mb-4">
        {/* Add remote video elements here as needed */}
      </div>

      {/* Active Speaker */}
      <div className="mt-4">
        <div className="bg-light rounded">
          <video
            id="activeSpeakerVideo"
            muted
            autoPlay
            playsInline
            style={{ width: '100%', height: '400px' }}
          ></video>
          <div id="activeSpeakerUsername" className="bg-light text-center py-2">
            {/* Active speaker username */}
          </div>
        </div>
      </div>

      {/* Local Video */}
      <div className="d-flex flex-column bg-light mt-4" style={{ width: '150px' }}>
        <video
          id="meetingAreaLocalVideo"
          muted
          autoPlay
          playsInline
          style={{ width: '150px', height: '100px' }}
        ></video>
        <div id="meetingAreaUsername" className="bg-light text-center py-2">
          {/* Local user username */}
        </div>
      </div>

      {/* Controls */}
      <div className="d-flex justify-content-center mt-4 gap-3">
        <button id="meetingViewMicrophone" className="btn btn-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            className="bi bi-mic"
            width="24"
            height="24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
            />
          </svg>
        </button>

        <button id="meetingViewCamera" className="btn btn-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            className="bi bi-camera-video"
            width="24"
            height="24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
            />
          </svg>
        </button>

        <button id="meetingViewScreen" className="btn btn-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            className="bi bi-tv"
            width="24"
            height="24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </button>

        <button id="meetingViewLeave" className="btn btn-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            className="bi bi-door-open"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default MeetingView;
