import PropTypes from "prop-types"
import React, { useState } from "react"

import { Link, withRouter } from "react-router-dom"

import { Form, InputGroup, Spinner } from "react-bootstrap"

// Toast Alert
import toast from "react-hot-toast"

// Importing Custom Components
import Logo from "./../../assets/images/Tehuti-Logo.png";

import AuthBody from "./AuthBody"

import * as auth from "models/auth"

const Login = props => {
  const [remember, setRemember] = useState(false)

  // Processing Globals
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPword, setShowPword] = useState(false)

  const toggleShowPass = () => setShowPword(!showPword)

  const handleLogin = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      userEmail: form.userEmail.value,
      password: form.password.value
    }
    auth.sendOtp(payload)
      .then(resp => {
        const data = resp.profile
        setIsLoading(false)
        localStorage.setItem("teutiUser", JSON.stringify(data)),
          props.history.push("/")
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const mainForm = (
    <div className=" mx-3 mx-md-5">
      <div className="w-100 bg-white">
        <div className="d-flex flex-column h-100 mx-4">
          <div className=" start-brand-box">
            <div className="logo logo-dark mt-2  ">
              <span className="logo-sm">
                <img src={Logo} alt="" height="40px" />
              </span>
              <span className="logo-lg">
                <img src={Logo} alt="" height="80px" className="image-fluid" />
              </span>
            </div>
          </div>

          <div className="my-4 mt-2">
            {/* <div>
              <h4 className="text-black fw-bold pb-3"> </h4>
            </div> */}

            {/* {forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {forgetSuccessMsg}
              </Alert>
            ) : null} */}
            <div className="mt-4">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleLogin}
                className=""
              >
                <Form.Group className="mb-3" controlId="userEmail">
                  <Form.Label>User Email/ PhoneNumber</Form.Label>
                  <InputGroup className="form-control-lgm">
                    <Form.Control
                      type="email"
                      placeholder="e.g. johnsmith@email.com"
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Email
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPword ? "text" : "password"}
                      placeholder="******"
                      required
                      className=""
                    />
                    <button
                      type="button"
                      onClick={e => toggleShowPass(e)}
                      className="btn btn-primary shadow-none "
                    >
                      {showPword ? (
                        <i className="fas fa-eye fs-4" />
                      ) : (
                        <i className="fas fa-eye-slash fs-4" />
                      )}
                    </button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Password
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="my-4">
                  <Link to="/forgot-password" className="text-primary ">
                    Forgot Password
                  </Link>
                </div>

                <div className="mt-4 d-grid mb-5">
                  <button
                    className="btn btn-primary btn-block btn-lg rounded-0"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing...
                      </>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <AuthBody
      form={mainForm}
      meta="Login | Tehuti Admin"
    />
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
