import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import Home from "pages/Home"
import Lectures from "pages/Lectures"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/lectures", component: Lectures },
  { path: "/", component: Home },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/Home" /> },
]

const publicRoutes = [
  // { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
