import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


//Levels
//post functions
export const getUploads = async (owner) => {
    return await axios
      .get(`${getApi.UPLOAD.GET_CONTENTS}/${owner}`,  {
        // headers: {
        //   "content-type": "application/json",
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err))
  }
  