import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Post Funtions
export const register = async (payload) => {
  return await axios
    .post(getApi.AUTH.REGISTER, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const verifyRegistration =async(token,userId)=>{
return await axios
.post(`${getApi.AUTH.VERIFY_REGISTRATION}/${userId}`,{
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
}

export const resendVerificationEmail=async(userId)=>{
return await axios
.post(`${getApi.AUTH.VERIFY_REGISTRATION}/${userId}`)
}

export const sendOtp = async (payload) => {
  return await axios
    .post(getApi.AUTH.SEND_OTP, payload)
    .then(async ({ data }) => {

      return data;
    })
    .catch(err => catchError(err, err));
};

export const verifyOtp = async (payload) => {
  return await axios
    .post(getApi.AUTH.VERIFY_OTP, payload)
    .then(async ({ data }) => {

      return data;
    })
    .catch(err => catchError(err, err));
};

export const logout =async()=>{
  return await axios
  .post(getApi.AUTH.LOGOUT)
  .then(async ({ data }) => {

    return data;
  })
  .catch(err => catchError(err, err));
}

export const getRefreshToken =async(token)=>{
  return await axios
  .post(getApi.AUTH.REFRESH_TOKEN,{
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
  .then(async ({ data }) => {

    return data;
  })
  .catch(err => catchError(err, err));
}

//profile
export const getProfile = async ( token) => {
  return await axios
    .put(`${getApi.AUTH.UPDATE_USER}/${userId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const updateProfile = async (token,userId, payload) => {
  return await axios
    .put(`${getApi.AUTH.UPDATE_USER}/${userId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};




//Delete Funtions

export const deleteUserProfile = async (token,userId) => {
  return await axios
    .delete(`${getApi.AUTH.VERIFY_DELETE_PROFILE}/${userId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}

export const verifyDeleteUserProfile = async (token,userId) => {
  return await axios
    .delete(`${getApi.AUTH.DELETE_PROFILE}/${userId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}







