import React from 'react'
import PropTypes from 'prop-types'

const Lectures = props => {
  return (
    <div>index</div>
  )
}

Lectures.propTypes = {}

export default Lectures